import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import _ from 'lodash';
import {CustomerProfile} from '../customer-profile.types';
import moment from 'moment';
import {IController, ILocationService, IOnChangesObject, IScope, ITimeoutService} from 'angular';

import templateUrl from './customer-profile-body.template.html';
import './customer-profile-body.style.less';
import './customer-profile-body.print.less';
import systemPropertyService from "../../../../../react/system/systemPropertyService";
import Authentication from "shared/utils/authentication";
import {BranchService} from "components/service/branch.service";
import {ModalPrintPreviewService} from "components/service/print/modal-print-preview.service";
import {AgentPhoneNumberService} from "components/agent-banking/service/agents-phone-number.service";
import {ProfileUtilityService} from "components/customer/profile/profile-utility.service";
import {RecursivePartial} from "shared/utils/RecursivePartial";
import {PropertyConfigService} from 'components/service/property-config.service';
import {WatchlistService} from 'components/service/watchlist.service';
import {Dict} from "shared/common/dict.types";
import {ProfileUpdateService} from "components/customer/profile/profile-update.service.types";
import {Confirmation} from "shared/common/confirmation.types";
import {SystemDateService} from "components/service/system-date.service.types";
import {HttpService} from "shared/utils/httpService";
import {NxRouteService} from "routes/NxRouteService";
import {CustomerCache} from "components/service/customer.cache.types";
import Notification from "shared/utils/notification";
import {CustomerType} from 'components/customer/profile/customer-profile.types';
import {CommandService} from "shared/utils/command/command.types";
import {ModalApi} from "components/technical/modal/modal.component";
import {RiskCategory} from "components/administration/compliance/risk-profiling-types";

class CustomerProfileBody implements IController {
  // bindings
  private profile!: RecursivePartial<CustomerProfile> & {customFieldValues: Record<number, string>};
  private editMode: boolean = false;
  private singlePage: boolean = false;
  protected hideDefaultButtons: boolean = false;
  protected watchlistVerification: any = {};
  protected module!: string;
  protected activeTab!: string;

  private singlePageSystemProperty: boolean = true;
  private corporateCustomerEnabled: boolean = false;
  private defaultUrl: string;
  private clonedCustomerProfileModel: RecursivePartial<CustomerProfile> & {customFieldValues: Record<number, string>} | null = null;
  protected creatingCustomer: boolean = false;
  protected availableCustomFields!: number[];
  private originalCategories: (number | undefined)[] = [];
  private originalCustomFieldValues: Record<number, string> = {};
  private subcategoryModalApi!: ModalApi;
  protected riskCategories!: RiskCategory[];
  protected riskCategoriesForManualSelection!: RiskCategory[];
  protected isAutomaticRiskProfiling: boolean;
  protected isRiskProfilingEnabled: boolean;
  protected isCifProfileHistoryEnabled: boolean;

  constructor(
    private $scope: IScope,
    private $route: NxRouteService,
    private $timeout: ITimeoutService,
    private $location: ILocationService,
    private dict: Dict,
    private customerCache: CustomerCache,
    private profileUpdateService: ProfileUpdateService,
    private confirmation: Confirmation,
    private authentication: Authentication,
    private systemDateService: SystemDateService,
    private branchService: BranchService,
    private notification: Notification,
    private modalPrintPreviewService: ModalPrintPreviewService,
    private http: HttpService,
    private agentPhoneNumberService: AgentPhoneNumberService,
    private profileUtilityService: ProfileUtilityService,
    // used in template
    protected propertyConfigService: PropertyConfigService,
    protected watchlistService: WatchlistService,
    private command: CommandService
  ) {
    const defaultUrl = systemPropertyService.getProperty('DEFAULT_URL_AFTER_CST_LOOKUP');
    this.defaultUrl = defaultUrl || '/customer/{}/accounts';
    this.isRiskProfilingEnabled = systemPropertyService.getProperty('RISK_PROFILING_ENABLED') === 'TRUE';
    this.isAutomaticRiskProfiling = systemPropertyService.getProperty('AUTOMATIC_RISK_PROFILING_AFTER_CREATE_CIF') === 'TRUE';
    this.activeTab = 'cpr_tab_base_data';
    this.isCifProfileHistoryEnabled = systemPropertyService.getProperty('CIF_PROFILE_HISTORY_ENABLED') === 'TRUE';

    $scope.$watch('$ctrl.profile',  async () => {
      if (!this.profile) {
        return;
      }

      if (this.profile.status !== 'CLOSED' && this.profile.shouldUpdateProfile) {
        this.enableEditMode();
      }

      if (this.profile.id) {
        this.profile.customFieldValues = await customFieldService.readValuesMap({customerId: this.profile.id});
        this.originalCustomFieldValues = _.cloneDeep(this.profile.customFieldValues);
      }
      if (this.profile.categoryIds) {
        this.originalCategories = _.cloneDeep(this.profile.categoryIds);
      }
    });
    $scope.$watch('$ctrl.profile.customerType', () => this.updateAvailableCustomFields());
  }

  onSubcategoryModalApiReady = ({api}: {
    api: ModalApi
  }): void => {
    this.subcategoryModalApi = api;
  };

  async updateAvailableCustomFields(): Promise<void> {
    if (!this.profile || !this.profile.customerType) {
      return;
    }

    const customerType = this.profile.customerType;
    const definitions = await customFieldService.readDefinitions({groups: [CustomFieldGroup.CUSTOMER], customerType: customerType});
    this.availableCustomFields = definitions.filter(c => c.customerTypes && c.customerTypes.includes(customerType))
      .map(d => d.id)
  }

  async $onInit(): Promise<void> {
    this.singlePageSystemProperty = systemPropertyService.getProperty('CIF_SINGLE_PAGE') === 'TRUE';
    // singlePage can be enabled at attribute level and then it takes precedence over sys property
    this.singlePage = this.singlePage || this.singlePageSystemProperty;
    this.corporateCustomerEnabled = systemPropertyService.getProperty('CIF_CORPORATE_SUPPORT') === 'TRUE';

    this.riskCategories = <RiskCategory[]> await this.http.get(`/management/risk/categories`).toPromise();

    this.riskCategoriesForManualSelection = this.riskCategories
        .filter(c => c.customerTypes.includes(this.profile.customerType as "INDIVIDUAL" | "CORPORATE" | "GROUP"))
        .map((c) => this.buildValuesForDropdownOptions(c));
  }

    private buildValuesForDropdownOptions(c: RiskCategory): RiskCategory {
      if (c.assignTo === 'NONE') {
        return {
          ...c,
          valuesForSelection: c.entries
        };
      } else if (c.assignTo === 'LINKED_CONDITIONS') {
        return {
          ...c,
          valuesForSelection: []
        };
      } else if (c.assignTo === 'SUBSTITUTED_VALUE') {
        return {
          ...c,
          valuesForSelection: c.entries
              .flatMap(e => {
                if (JSON.stringify(e.conditions) === '{}') {
                  return [{id: e.id, description: e.description}];
                } else if (JSON.stringify(e.conditions).includes('substitutedValue')) {
                  return e.conditions.substitutedValue.options.map((o: string) => ({
                          id: e.id,
                          description: o
                      })
                  );
                } else {
                  return [];
                }
              })
        };
      } else {
        return c;
      }
    }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.editMode) {
      if (changes.editMode.currentValue) {
        this.enableEditMode();
      } else if (!changes.editMode.currentValue && !changes.editMode.isFirstChange()) {
        this.cancelEditCallback();
      }
    }
  }

  enableEditMode(): void {
    this.editMode = true;
    this.clonedCustomerProfileModel = _.cloneDeep(this.profile);
  }

  disableEditMode() {
    this.editMode = false;
  }

  cancelEditCallback() {
    this.profile = this.clonedCustomerProfileModel!;
    this.disableEditMode();
    this.$route.reload();
  }

  async cancelEdit() {
    if (await this.confirmation('Do you want to cancel? Canceling will discard all changes.')) {
      this.cancelEditCallback();
    }
  }

  async reset(forceReset: boolean) {
    if (forceReset || confirm('Do you really want to reset all fields?')) {
      if (this.profile.id) {
        this.profile = _.cloneDeep(this.clonedCustomerProfileModel)!;
      } else {
        const customerSubtype = this.profile.customerSubtype;
        const customerType = this.profile.customerType;
        const profile = await this.profileUtilityService.initCustomerProfile();
        this.profile = {
          ...profile,
          customerType: customerType,
          customerSubtype: customerSubtype,
          customFieldValues: {}
        }
      }
    }
  }

  async createCustomer() {
    try {
      if (!await this.hasValidDocuments() && !await this.confirmation('Are you sure you want to proceed even though the ID is expired?')) {
        return;
      }

      this.creatingCustomer = true;
      await this.executeCreateCustomer();
    } finally {
      this.creatingCustomer = false;
    }
  }

  async updateCustomer() {
    if (!await this.hasValidDocuments() && !await this.confirmation('Are you sure you want to proceed even though the ID is expired?')) {
      return;
    }
    this.synchronizeOldCustomFieldValues();
    await this.sanitizeRelatedCustomerTypeIds();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const command = this.profileUpdateService.update(this.profile, this.clonedCustomerProfileModel, this.profile.shouldUpdateProfile);
    if (command) {
      await command.toPromise();
      this.customerCache.refetch();
      if(this.profile.id) {
        await this.agentPhoneNumberService.refreshUserPhoneNumber(this.profile.id);
      }

      if (this.profile.shouldUpdateProfile) {
        this.$location.path(`/customer/${this.profile.id}/profile`)
      } else {
        this.$route.reload();
      }

    } else {
      // no changes were made
      this.disableEditMode();
    }
  }

  getMinRelatedCustomers() : number {
    switch (this.profile?.customerType) {
      case 'INDIVIDUAL':
        return parseInt(systemPropertyService.getProperty('CIF_MIN_RELATED_CUSTOMER_INDIVIDUAL') || '0');
      case 'CORPORATE':
        return parseInt(systemPropertyService.getProperty('CIF_MIN_RELATED_CUSTOMER_CORPORATE') || '0');
      default:
        return 0;
    }
  }

  synchronizeOldCustomFieldValues(): void {
    if (this.clonedCustomerProfileModel) {
      this.clonedCustomerProfileModel.categoryIds = this.originalCategories;
      this.clonedCustomerProfileModel.customFieldValues = this.originalCustomFieldValues;
    }
  }

  async hasValidDocuments() {
    const systemDate = await this.getBranchSystemDate();
    return (this.profile.idDocuments ?? [])
      .filter(idDocument => idDocument?.expirationSupported)
      .map(idDocument => idDocument!.expirationDate)
      .map(expirationDate => moment(expirationDate))
      .every(expirationDate => expirationDate.isAfter(systemDate, 'day'));
  }

  async getBranchSystemDate() {
    return this.systemDateService.getCurrentUserBranchSystemDate();
  }

  async executeCreateCustomer() {
    await this.sanitizeRelatedCustomerTypeIds();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const profile: Partial<CustomerProfile> = _.cloneDeep(this.profile);
    this.prepareCustomerForSaving(profile);

    await this.prepareCustomerRiskProfilingInputs(profile);

    const response = await this.profileUpdateService.create(profile).toPromise();

    if (response.output) {
      const customerId = response.output.id;
      if (profile.customerType === 'INDIVIDUAL' || profile.customerType === 'CORPORATE') {
        await this.modalPrintPreviewService.showAsync({
          printDescription: 'SIGNATURE_CARD',
          printProviderInput: {customerId: customerId}
        });
      }

      await this.reset(true);
      this.performRedirect(customerId);
      this.notification.show('Success', 'Customer profile created successfully.');
    }
  }

  private async sanitizeRelatedCustomerTypeIds() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.profile.relatedCustomers.forEach(rc => rc.typeIds = [...new Set(rc.typeIds.filter(t => ![-1, null, undefined].includes(t)))]);
  }

  async prepareCustomerRiskProfilingInputs(profile: Partial<CustomerProfile>) {
    if (this.isRiskProfilingEnabled && this.isAutomaticRiskProfiling
      && this.riskCategoriesForManualSelection
        .some((c) => c.assignTo === 'NONE' || c.assignTo === 'SUBSTITUTED_VALUE')
    ) {
      const result = await this.subcategoryModalApi.show();

      if (result?.accepted) {
        const selectedCategoryEntryIds =
          [...new Set(
            this.riskCategoriesForManualSelection.flatMap(rc => {
                if (rc.selectorType === 'SINGLE_SELECT') {
                  return rc.selectedSubcategoryIds;
                } else {
                  return rc.selectedSubcategoryIds ? rc.selectedSubcategoryIds.map(s =>
                    rc.valuesForSelection.find(v => v.id === s)?.id
                  ) : [];
                }
              }
            )
          )];

        profile.selectedCategoryEntryIds = selectedCategoryEntryIds;
      }
    } else if (this.isRiskProfilingEnabled && this.isAutomaticRiskProfiling) {
      profile.selectedCategoryEntryIds = [];
    }
  }

  /**
   * After successful customer creation user should be redirected to other page.
   *
   * For BANK -> redirect to actions
   */
  async performRedirect(customerId: number) {
    try {
      await this.http.get(`/customers/${customerId}`).toPromise();
      const redirectionUrl = this.defaultUrl.replace('{}', String(customerId));
      this.$location.path(redirectionUrl);
    } catch (error) {
      // if customer is prospect => 404 will be returned
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path('/dashboard/lookup-customer')
  }

  prepareCustomerForSaving(profile: Partial<CustomerProfile>) {
    if (profile.customerType === 'INDIVIDUAL') {
      profile.corporateData = undefined;
    } else {
      profile.individualData = undefined;
    }
  }

  resetWatchlistVerification(): void {
    this.watchlistVerification = {
      completed: false
    }
  }

  async verifyWatchlist(input: any) {
    this.watchlistVerification = await this.watchlistService.verify(input);
    this.profile.watchlistMatch = this.watchlistVerification.watchlistMatch;
    this.profile.confirmed = this.watchlistVerification.confirmed;
    this.profile.watchlistVerified = this.watchlistVerification.completed;
  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
  }
}

nxModule.component('customerProfileBody', {
  templateUrl: templateUrl,
  controller: CustomerProfileBody,
  transclude: {
    editDisabled: '?buttonsWhenEditDisabled',
    alwaysVisible: '?buttonsAlwaysVisible'
  },
  bindings: {
    profile: '=',
    editMode: '<',
    singlePage: '<',
    hideDefaultButtons: '<',
    module: '<'
  }
});
